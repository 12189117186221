/* eslint-disable no-console,no-undef */
/* eslint-disable react/no-unknown-property */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  EventDetailsContainer, ContainerSecond, ContainerFirst
} from '../../EventDetails/styles';
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { likeUnlikeEducationPost } from '../../../redux/actions';
import { updateUserGlobalPoints } from '../../../redux/actions';
import { VideoContainerNew, CircleContainerNew, Tick, Circle, ImageContainerIcons, VideoContainer, Container, OndemandRightSection} from './styles';
import { OverlayTrigger, Popover } from "react-bootstrap";
import { EventHeading } from '../OnDemandDetailsPage/style';
import { StyledSvg, ToolTip, BuddyTab, AboutSection} from '../../common/commonStyles';
import { BackArrow, EditIconUpdated} from '../../../utils/icons';
import { ImageUrl } from '../../../utils/constants';
import { createSafeHTML } from '../../../utils/methods';
import CoachProfile from "../../EventDetails/CoachProfile";
import SkeletonLoder from '../../common/skeletonLoder';
// import ReactPlayer from 'react-player';

class OnDemandVideoDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: [],
      buttonStatus: 0,
      save: 0,
      like: 0,
      imagestatus: 0,
      ondemandDelete: false,
      ondemandEdit: false,
      showBack:false,
      ReactPlayer: null, // store dynamic ReactPlayer component
      loadingVideo: true
    }
  }

  componentDidMount() {
    this.loadReactPlayer();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.eventDetail?.video_url !== this.props.eventDetail?.video_url) {
      this.loadReactPlayer();
    }
  }

  loadReactPlayer = async () => {
    const { video_url } = this.props.eventDetail || {};

    try {
      let ReactPlayer;

      if (video_url?.includes('vimeo.com')) {
        console.log("Loading Vimeo ReactPlayer");
        ReactPlayer = (await import('react-player/vimeo')).default;
      } else if (video_url?.includes('youtube.com') || video_url?.includes('youtu.be')) {
        ReactPlayer = (await import('react-player/youtube')).default;
      } else {
        ReactPlayer = (await import('react-player')).default;
      }

      this.setState({ ReactPlayer });
    } catch (error) {
      console.error("Error loading ReactPlayer:", error);
    }
  };

  onClickFavoriteRibbon = (type, typeId, likeStatus, favoriteStatus, featureType = 1) => {
    const { likeUnlikeEducationPost } = this.props;
    let obj =
    {
      "type": type,
      "type_id": typeId,
      "like_status": likeStatus,
    }
    if (featureType === 0) {
      obj['favorite_status'] = favoriteStatus;
    }
    likeUnlikeEducationPost(obj);
  }


  addLikes = (journeys) => {
    this.onClickFavoriteRibbon("webinar", journeys.id, journeys.like_status ? 0 : 1, journeys.favorite_status, 1)
    this.setState({
      like: !this.state.like,
      imagestatus: journeys.like_status === 1 ? 1 : 0
    })
  };

  saveImage = () => {
    this.setState({
      save: !this.state.save,
    })
  }

  updateUserPoints = () => {
    const { updateUserGlobalPoints, eventDetail } = this.props;
    updateUserGlobalPoints(eventDetail.id, 18, this.callBack);
  };

  callBack = () => {
    const { fetchOndemandEventDetailsById, eventDetail } = this.props;
    fetchOndemandEventDetailsById(eventDetail.id);
  }

  navigateEdit = () => {
    const { history } = this.props;
    const { pathname } = history.location;
    const StringArray = pathname.split('/');
    if (StringArray[StringArray.length - 1] != '') {
      history.push(`/company/events/edit-ondemand/${StringArray[StringArray.length - 1]}`);
    } else {
      history.push(`/company/events/edit-ondemand/${StringArray[StringArray.length - 2]}`);
    }
  }

  eventButtonStatusHover = (value) => {
    if(value==="back"){
      this.setState({
        showBack: true,
      });
    }
    else if (value !== "event"){
      this.setState({
        ondemandDelete: true,
      });
    }
    else {
      this.setState({
        ondemandEdit: true,
      });
    }
  };

  eventButtonStatus = (value) => {
    if(value==="back")(
      this.setState({
        showBack: false,
      })
    ) 
    else if (value !== "event"){
      this.setState({
        ondemandDelete: false,
      })
    } 
    else {
      this.setState({
        ondemandEdit: false,
      });
    }
  };

  renderHeading = () => {
    const { ondemandEdit, showBack} = this.state;
    const { role } = this.props;
    const popover = (value) => (
      <Popover
        id="popover-basic"
        style={{
          width: "auto",
          padding: "9px 14px",
          background: "#FFFFFF",
          border: "1px solid #005c87",
          boxShadow: "0px 13px 28px rgba(0, 0, 0, 0.3)",
          borderRadius: "6px",
        }}
      >
        <div
          style={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#005c87",
            textAlign: "center",
          }}
        >
          {this.props.t(value)}
        </div>
      </Popover>
    );

    return (
      <EventHeading margin="25px" style={{height:'55px'}}>
        <ToolTip
          margin={"auto 0px auto 15px"}
          active={showBack&&"#007AB1"}
          fill="#007AB1"
          onMouseEnter={() => this.eventButtonStatusHover("back")}
          onMouseLeave={() => this.eventButtonStatus("back")}
          height="40px"
          width="40px"
        >
          <OverlayTrigger placement="bottom" overlay={popover(this.props.t("Back"))}>
            <div
              className='toolTip'
              onClick={() =>
              {
                if(window.location.pathname.includes('company')){
                  this.props.history.push("/company/dashboard");
                }else{
                  this.props.history.push("/events");
                }
              } }
            >
              <StyledSvg active={showBack} width="24px" height="30px">
                <BackArrow/>
              </StyledSvg>
            </div>
          </OverlayTrigger>
        </ToolTip>
        <BuddyTab active>{this.props.t("Event Brief")}</BuddyTab>
        {role === "ADMIN" && (
          <ToolTip
            margin={"auto 0px auto auto"}
            active={ondemandEdit}
            onMouseEnter={() => this.eventButtonStatusHover("event")}
            onMouseLeave={() => this.eventButtonStatus("event")}
          >
            <OverlayTrigger
              placement="bottom"
              overlay={popover(this.props.t("Edit On-Demand Event"))}
            >
              <div
                className='toolTip'
                onClick={this.navigateEdit}
              >
                <StyledSvg width="24px" height="24px"   active={ondemandEdit}>
                  <EditIconUpdated/>
                </StyledSvg>
              </div>
            </OverlayTrigger>
          </ToolTip>
        )}
      </EventHeading>
    );
  };

  render() {
    const { eventDetail, t } = this.props;
    const { like, imagestatus, ReactPlayer, loadingVideo } = this.state;
    return (
      <Container>
        {this.renderHeading()}
        <EventDetailsContainer background padding>
          <ContainerFirst>
            <div className='title'>{eventDetail.title}</div>
            <VideoContainer height={"100%"}  background  marginBottom="0px" >
              <VideoContainerNew loadingVideo={loadingVideo}>
                {ReactPlayer ? (<ReactPlayer
                  className={"react-player"}
                  url={eventDetail?.video_url}
                  onEnded={this.updateUserPoints}
                  height="100%"
                  controls
                  key={eventDetail?.video_url}
                  onReady={() => this.setState({ loadingVideo: false })}
                  // light={true}
                />):<SkeletonLoder height={"100%"}/>}
                {loadingVideo ? <SkeletonLoder width={"640px"} height={"470px"}/>:null}
              </VideoContainerNew>
              <ImageContainerIcons margin={"0px"}>
                <div>
                  <div>
                    <img src={ImageUrl+"/event-section/eye-view.png"} alt="view" />
                    <span>{eventDetail.seen_count}</span>
                  </div>
                  <div style={{color:"#005c87"}}>
                    {!imagestatus && (like || eventDetail.like_status) ? <img src={ImageUrl+"/social-feed/like_filed.png"} alt="like" onClick={() => this.addLikes(eventDetail)} /> : <img src={ImageUrl+"/social-feed/like.png"} alt="like" onClick={() => this.addLikes(eventDetail)} />}
                    {like ? <span>{eventDetail.like_status ? ((eventDetail.likes_count) - 1) : ((eventDetail.likes_count) + 1)}</span> : <span>{(eventDetail.likes_count)}</span>}
                  </div>
                </div>
                <div >
                  <CircleContainerNew style={{ "marginLeft": "15px" }} font="1" color="1">
                    <div className="flex">
                      {eventDetail.view_status?
                        <img src={ImageUrl+"/event-section/read-arrow.png"} height={"24px"} width={"24px"}/>:
                        <Circle borderColor="#005c87" checked={eventDetail.view_status}>
                          <Tick checked={eventDetail.view_status} />
                        </Circle>}
                    </div>
                    <div className="quiz" style={{color:"#005c87"}}>
                      {t("Watched")}
                    </div>
                  </CircleContainerNew>
                </div>
              </ImageContainerIcons>
              <AboutSection>
                <div className='about-heading'>
                  {t("About the event")}
                </div>
                <div className='details' /*dangerouslySetInnerHTML={{ __html: this.props.t(eventDetail.description) }}*/>{createSafeHTML(this.props.t(eventDetail.description))}</div>
              </AboutSection>
            </VideoContainer>
          </ContainerFirst>
          <ContainerSecond>
            <OndemandRightSection>
              <div className='ondemand-button'>
                {t("On-Demand Event")}
              </div>
              <div className='ondemand-details'>
                <div className='image'>
                  <img src={ImageUrl+"/"+eventDetail.category_image}/>
                </div>
                <div className='name'>
                  {this.props.t(eventDetail.category_name)}
                </div>
              </div>
              <div className='ondemand-details'>
                <div className='image'>
                  <img src={ImageUrl+"/event-section/time.png"}/>
                </div>
                <div className='name'>
                  {Math.round(eventDetail.time)}&nbsp;{t("Mins")}
                </div>
              </div>
              <div className='ondemand-details'>
                <div className='image'>
                  <img src={ImageUrl+"/event-section/points.png"}/>
                </div>
                <div className='name'>
                  +{eventDetail.point_value}{" "}{t("Points")}
                </div>
              </div>
            </OndemandRightSection>
          </ContainerSecond>
        </EventDetailsContainer>
        {eventDetail?.coach_id && (<div style={{ marginTop: "40px", marginBottom: "80px", display:"flex",justifyContent:"center" }}>
          <CoachProfile coachDetail={eventDetail?.coach_details} history={this.props.history}/>
        </div>)}
      </Container>
    )
  }
}

OnDemandVideoDetail.propTypes = {
  t: PropTypes.func,
  history: PropTypes.object.isRequired,
  fetchEventDetailsById: PropTypes.func.isRequired,
  location: PropTypes.object,
  eventDetail: PropTypes.object,
  likeUnlikeEducationPost: PropTypes.func,
  updateUserGlobalPoints: PropTypes.func.isRequired,
  fetchOndemandEventDetailsById: PropTypes.func.isRequired,
  permission: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  role: PropTypes.string
};

const mapStateToProps = (state) => ({
  activeEvents: state.events.activeEvents,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserGlobalPoints: (actionId, activityType, callBackForUpdaingApiDetails) => dispatch(updateUserGlobalPoints(actionId, activityType, callBackForUpdaingApiDetails)),
  likeUnlikeEducationPost: (obj) => dispatch(likeUnlikeEducationPost(obj)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OnDemandVideoDetail)));